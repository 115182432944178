
class App {
    
    constructor() {
        this.theme_name = 'light';
        this.page_name = 'index';
        
        this.android_store_link = "https://play.google.com/store/apps/details?id=io.wobis.app";
        this.apple_store_link = "https://apps.apple.com/app/wobis-app/id6599845862";
    }
    
    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }
    
    init() {
        const urlParams = new URLSearchParams(this.htmlDecode(queryString));
        const params = Object.fromEntries(urlParams.entries());
        
        this._renderHtmlLayout();

        if (queryString != null) {
            document.location.href = 'wobis://open.com' + this.htmlDecode(queryString);
        } else {
            document.location.href = 'wobis://open';
        }

        this.redirectToShop();
        
        this.render('shop', params);
    }
    
    _renderHtmlLayout() {
        $('head').append(`
            <meta name="color-scheme" content="${this.theme_name}">
            <link rel="stylesheet" href="css/${this.theme_name}.css">
        `);
    }
    
    async render(name, params = {}) {
        params.full_url = this.htmlDecode(urlString);
        var system = this.checkSystem();
        params.system = system;
        
        name = name.toLowerCase();
        if(this.page) {
            await this.page.destroy();
        }
        
        var class_name = name.split('_').map(x => x.ucFirst()).join('') + 'Page';
        this.page_name = name;
        
        this.page = eval('new '+class_name+'(params, this);');
        await this.page.render();
    }
    
    checkSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/android/i.test(userAgent)) {
            var system = "android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            var system = "ios";
        } else {
            var system = "unknown";
        }
        
        return system;
    }
    
    redirectToShop() {
        switch (this.checkSystem()) {
            case 'android':
            default:
                window.location.href = this.android_store_link;
                break;
            case 'ios':
                window.location.href = this.apple_store_link;
                break;
        }
    }
}

var app = new App();
app.init();
